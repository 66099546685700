import { h } from 'preact';
import LoadingDots from './LoadingDots';
import { useText } from 'preact-i18n';

const SigninJoinCard = ({ buttonText, isLoading, onClick }) => {
  const { doters_points, win_initial, travel_doters } = useText({
    doters_points: 'label.doters_points',
    win_initial: 'label.win_initial',
    travel_doters: 'label.travel_doters',
  });

  return (
    <div className="join-card" onClick={onClick} title={buttonText}>
      <p className="join-card-message">
        {`¡${win_initial}`}

        <i className="doters-basic" />
        <b>{doters_points}</b>

        {`${travel_doters}!`}
      </p>

      {isLoading ? (
        <LoadingDots />
      ) : (
        <button type="button" className="join-card-button" onClick={onClick}>
          {buttonText}
        </button>
      )}
    </div>
  );
};

export default SigninJoinCard;
