import { Component, h } from 'preact';
import Modal from './generics/Modal';
import { Text } from 'preact-i18n';
import modalContent from '../constants/modalContent';
import ProfileContent from './ProfileContent';
import Cookies from 'js-cookie';
import { createSSOLoginUrl, createSSOLogoutUrl } from '../utils/sso';
import psl from 'psl';
import withConfig from './hoc/withContext';
import DotersHeader from './DotersHeader';
// import ResetPassContent from './ResetPassContent';

class ProfileModal extends Component {
  constructor() {
    super();
    this.state = {
      modalContent: modalContent.SIGN_IN,
      isLoading: false,
    };
    this.redirectedToSSOKey = 'redirectedToSSO';
    this.redirectSSO = this.redirectSSO.bind(this);
    this.signInClick = this.signInClick.bind(this);
    this.signUpClick = this.signUpClick.bind(this);
    this.handleSSOSignOut = this.handleSSOSignOut.bind(this);
  }

  componentDidMount() {
    // Reload the page if the cache page persists to avoid the doters loader
    window.onpageshow = event => {
      if (event.persisted && localStorage.getItem(this.redirectedToSSOKey)) {
        localStorage.removeItem(this.redirectedToSSOKey);
        window.location.reload();
      } else {
        localStorage.removeItem(this.redirectedToSSOKey);
      }
    };

    // eslint-disable-next-line react/no-did-mount-set-state
    this.setState({ modalContent: modalContent.SIGN_IN });

    const {
      widgetProps: {
        variant,
        ssoHost,
        ssoLogoutHost,
        ssoClientId,
        ssoLogoutRedirectUri,
      },
    } = this.props;
    if (ssoHost)
      this.ssoLogoutUrl = createSSOLogoutUrl(
        ssoLogoutHost,
        ssoClientId,
        ssoLogoutRedirectUri,
      );
    if (variant === 'link')
      window.reservamosPubSub.sub('siempreplus-signIn', this.redirectSSO);
  }

  redirectSSO(registerParam) {
    const {
      widgetProps: {
        ssoHost,
        ssoClientSecret,
        ssoClientId,
        ssoRedirectKeyLogin,
        ssoLoginRedirectUri,
      },
    } = this.props;
    const ssoUrl = createSSOLoginUrl({
      ssoHost,
      ssoLoginRedirectUri,
      ssoClientSecret,
      ssoClientId,
      registerParam,
    });
    localStorage.setItem(ssoRedirectKeyLogin, window.location.href);
    localStorage.setItem(this.redirectedToSSOKey, true);
    this.setState({ isLoading: true });
    window.location = ssoUrl;
  }

  signInClick() {
    const { mixpanelTracker } = this.props;
    mixpanelTracker.trackSignInClick(null);
    this.redirectSSO(0);
  }

  signUpClick() {
    const { mixpanelTracker } = this.props;
    mixpanelTracker.trackSignUpClick(null);
    this.redirectSSO(1);
  }

  handleSSOSignOut() {
    localStorage.setItem(this.redirectedToSSOKey, true);
    this.setState({ isLoading: true });
    const {
      widgetProps: { ssoRedirectKeyLogout },
      config: { tokenName },
    } = this.props;
    localStorage.setItem(ssoRedirectKeyLogout, window.location.href);
    const domain = psl.parse(window.location.hostname).domain;
    Cookies.remove(tokenName, { domain });
    window.location.href = this.ssoLogoutUrl;
  }

  getModalElements() {
    const {
      session,
      mixpanelTracker,
      thirdParty,
      loggedIn,
      widgetProps,
      user,
    } = this.props;
    const { modalContent: currentModalContent, isLoading } = this.state;

    const props = {
      onChangeContent: newModalContent =>
        this.setState({ modalContent: newModalContent }),
      session,
      mixpanelTracker,
      thirdParty,
      loggedIn,
      widgetProps,
      user,
      signInClick: this.signInClick,
      signUpClick: this.signUpClick,
      signOut: this.handleSSOSignOut,
      isLoading,
    };

    switch (currentModalContent) {
      case modalContent.SIGN_IN:
        return {
          title: widgetProps.signInTitle,
          content: <ProfileContent {...props} />,
          // content: <ResetPassContent {...props} />,
        };
      default:
        return null;
    }
  }

  render({ headerColor, modalLogo }) {
    const elements = this.getModalElements();
    const { isLoading } = this.state;
    const {
      user,
      modifierClass,
      onClose,
      onShowModal,
      showModal,
      variant,
    } = this.props;
    const levelToShow = user ? user.level.toLowerCase() : '';
    const levelClassMapping = {
      gold: 'premium',
      silver: 'elite',
      green: 'basic',
    };

    const levelClass = levelClassMapping[levelToShow] || '';

    let callToAction = user && (
      <div
        onClick={onShowModal}
        className={`user-info ${modifierClass} user-info-doters user-info-icon-doters`}
      >
        <div className="indicator-wrapper">
          <i className={`icon-user-info doters-${levelClass} doters-bigger`} />
          <span className="online-indicator" />
        </div>

        <p className="user-info-title">
          <span>
            <Text id="text.hello" />
          </span>
          <b className="user-name">{user.first_name}</b>
        </p>
      </div>
    );

    if (variant === 'header')
      callToAction = <DotersHeader user={user} onShowModal={onShowModal} />;
    return (
      <div>
        {user && callToAction}
        {showModal && (
          <Modal
            title={elements.title}
            onUserClose={onClose}
            modalLogo={modalLogo}
            headerColor={headerColor}
            isLoading={isLoading}
          >
            {elements.content}
          </Modal>
        )}
      </div>
    );
  }
}

export default withConfig(ProfileModal);
