import { h } from 'preact';
import { Text } from 'preact-i18n';
import LoadingPulse from './LoadingPulse';

const SignInLink = ({
  onClick,
  modifierClass,
  isLoading,
  iconType,
  hideIcon,
  buttonLogo,
  showMobileIcon,
  showSigninText,
}) =>
  isLoading ? (
    <LoadingPulse modifierClass={modifierClass} />
  ) : (
    <div className={`signin ${modifierClass}`}>
      <button
        className={`signin-button ${
          showMobileIcon ? 'signin-button-icon' : ''
        }`}
        type="button"
        onClick={onClick}
        aria-label="signin-button"
      >
        {showSigninText && (
          <span className="divider">
            <Text id="button.log_in" />
          </span>
        )}

        {buttonLogo ? (
          <i className={`signin-logo-${buttonLogo}  `} />
        ) : (
          <div>
            {hideIcon || <i className={`badge-${iconType}`} />}
            <span className="signin-label">
              <Text id="button.log_in" />
            </span>
          </div>
        )}
      </button>
    </div>
  );

export default SignInLink;
