import { h } from 'preact';
import { useState, useEffect, useContext } from 'preact/hooks';
import { getPubSubEventName } from '../utils/pubSub';
import ProfileModal from './ProfileModal';
import SiemprePlusUserInfo from './SiemprePlusUserInfo';
import CostapassUserInfo from './CostapassUserInfo';
import Cookies from 'js-cookie';
import psl from 'psl';
import ConfigContext from './context/ConfigContext';

const UserInfo = ({
  variant,
  user,
  session,
  modifierClass,
  mixpanelTracker,
  widgetProps,
  loyalty,
}) => {
  const [showModal, setShowModal] = useState(false);
  const config = useContext(ConfigContext);
  const { pub } = window.reservamosPubSub;
  const {
    profile: profileEventName,
    signOut: signOutEventName,
  } = getPubSubEventName(widgetProps.cardType);

  const { tokenName } = config;

  const signOut = needsRequest => {
    if (needsRequest) {
      session
        .signOut({ logoutApi: widgetProps.logoutApi })
        .then(() => {
          mixpanelTracker.trackSignOut(user.sp_card);
          pub(profileEventName, {
            loading: false,
            user: null,
            error: false,
          });
        })
        .catch(console.warn);
    } else {
      const { domain } = psl.parse(window.location.hostname);
      Cookies.remove(config.tokenName, { domain });
      pub(profileEventName, {
        loading: false,
        user: null,
        error: false,
      });
    }
  };

  useEffect(() => {
    if (variant === 'link')
      window.reservamosPubSub.sub(signOutEventName, signOut);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isRegistered = user.customer_type === 'SPP';

  const { cardType } = widgetProps;

  const cardTypes = {
    doters: 'membership',
    viajamas: 'membership',
    costapass: 'membership',
    default: 'member_number',
  };
  const pointsTypes = {
    doters: 'points',
    viajamas: 'points',
    costapass: 'points',
    default: 'kilometres',
  };
  const cardLabel = cardTypes[cardType] ?? cardTypes.default;
  const kmsLabel = pointsTypes[cardType] ?? pointsTypes.default;
  const modalTypes = {
    doters: (
      <ProfileModal
        session={session}
        mixpanelTracker={mixpanelTracker}
        loggedIn={true}
        modalLogo={true}
        headerColor={'#232323'}
        widgetProps={widgetProps}
        modalIcon={null}
        user={user}
        modifierClass={modifierClass}
        showModal={showModal}
        onShowModal={() => setShowModal(true)}
        onClose={() => setShowModal(false)}
        variant={variant}
      />
    ),
    costapass: (
      <CostapassUserInfo
        user={user}
        signOut={signOut}
        modifierClass={modifierClass}
        loyalty={loyalty}
        accountUrl={widgetProps.accountUrl}
        tokenName={tokenName}
      />
    ),
    default: (
      <SiemprePlusUserInfo
        user={user}
        cardLabel={cardLabel}
        kmsLabel={kmsLabel}
        isRegistered={isRegistered}
        signOut={() => signOut(true)}
        modifierClass={modifierClass}
      />
    ),
  };

  let componentType = modalTypes[cardType] ?? modalTypes['default'];

  const shouldNotShow = variant === 'button' || variant === 'link-home';

  return shouldNotShow ? null : <div>{componentType}</div>;
};

export default UserInfo;
